<template>
  <div
    v-if="bet.playerAccountId"
    class="player-account-id"
    @click.stop="openCustomerProfileDetails"
  >
    <span class="player-account-id__label">
      {{ bet.playerAccountId }}
    </span>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const openCustomerProfileDetails = () => {
      router.push({
        name: 'customer-profiling-details',
        params: {
          customerId: props.bet?.playerAccountId,
        },
        query: {
          operator: props.bet?.client,
        },
      });
    };

    return {
      openCustomerProfileDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.player-account-id {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  &:hover .player-account-id__label {
    text-decoration: underline;
  }

  & .player-account-id__label {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}
</style>
