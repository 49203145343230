<template>
  <div class="bet-ticker-filter-sport-and-competition-select">
    <div class="bet-ticker-filter-sport-and-competition-select__form-group">
      <div class="bet-ticker-filter-sport-and-competition-select__form-label">
        Sport
      </div>
      <div
        ref="sportSelectRootElement"
        class="bet-ticker-filter-sport-and-competition-select__select"
      >
        <div
          :class="[
            'bet-ticker-filter-sport-and-competition-select__select-field',
            { 'bet-ticker-filter-sport-and-competition-select__select-field--is-active': isSportsMenuOpen },
          ]"
          @click="toggleSportsMenu"
        >
          <div class="bet-ticker-filter-sport-and-competition-select__select-field-value">
            <span class="bet-ticker-filter-sport-and-competition-select__select-field-label">
              {{ selectedSportLabel }}
            </span>
          </div>
          <Icon
            class="bet-ticker-filter-sport-and-competition-select__select-field-remove"
            name="x"
            @click.stop="removeSport"
          />
        </div>
        <div
          v-if="isSportsMenuOpen"
          class="bet-ticker-filter-sport-and-competition-select__select-menu"
        >
          <div
            v-if="isSportsLoading"
            class="bet-ticker-filter-sport-and-competition-select__select-item"
          >
            <Spinner
              small
            />
            <span>
              Fetching...
            </span>
          </div>
          <div
            v-for="sport in allSports"
            :key="sport.sportId"
            class="bet-ticker-filter-sport-and-competition-select__select-item"
            @click="setSport(sport)"
          >
            <RadioInput
              :checked="isSportSelected(sport)"
            />
            <span>
              {{ sport.sportName }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="bet-ticker-filter-sport-and-competition-select__form-group">
      <div class="bet-ticker-filter-sport-and-competition-select__form-label">
        Competition
      </div>
      <div
        ref="competitionSelectRootElement"
        :class="[
          'bet-ticker-filter-sport-and-competition-select__select',
          { 'bet-ticker-filter-sport-and-competition-select__select--is-disabled': !selectedSport }
        ]"
      >
        <div
          :class="[
            'bet-ticker-filter-sport-and-competition-select__select-field',
            { 'bet-ticker-filter-sport-and-competition-select__select-field--is-active': isCompetitionsMenuOpen },
          ]"
          @click="toggleCompetitionsMenu"
        >
          <div class="bet-ticker-filter-sport-and-competition-select__select-field-value">
            <span class="bet-ticker-filter-sport-and-competition-select__select-field-label">
              {{ selectedCompetitionsLabel }}
            </span>
            <span class="bet-ticker-filter-sport-and-competition-select__select-field-suffix">
              {{ selectedCompetitionLabelSuffix }}
            </span>
          </div>
          <Icon
            class="bet-ticker-filter-sport-and-competition-select__select-field-remove"
            name="x"
            @click.stop="removeCompetition"
          />
        </div>
        <div
          v-if="isCompetitionsMenuOpen"
          class="bet-ticker-filter-sport-and-competition-select__select-menu"
        >
          <div class="bet-ticker-filter-sport-and-competition-select__select-search">
            <TextInput
              v-model="competitionSearch"
              placeholder="Search competitions..."
              small
            />
          </div>
          <div
            v-if="isCompetitionsLoading"
            class="bet-ticker-filter-sport-and-competition-select__select-item"
          >
            <Spinner
              small
            />
            <span>
              Fetching...
            </span>
          </div>
          <div
            v-for="competition in allCompetitions"
            :key="competition.competitionId"
            class="bet-ticker-filter-sport-and-competition-select__select-item"
            @click="toggleCompetition(competition)"
          >
            <Checkbox
              :model-value="isCompetitionSelected(competition)"
              @update:model-value="toggleCompetition(competition)"
            />
            <span>
              {{ competition.competitionName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  includes,
  map,
  filter,
  trim,
  debounce,
} from 'lodash';
import {
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import { onClickOutside } from '@vueuse/core';
import { findSportsAndCompetitions } from '@/services/api';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import Checkbox from '@/components/common/Checkbox';
import RadioInput from '@/components/common/RadioInput';
import TextInput from '@/components/common/TextInput';

export default {
  components: {
    Icon,
    Spinner,
    Checkbox,
    RadioInput,
    TextInput,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:filter': {
      type: Object,
    },
    removeFilter: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const currentFilter = ref(cloneDeep(props.filter));
    const syncCurrentFilter = () => {
      const updatedFilter = cloneDeep(currentFilter.value);
      emit('update:filter', updatedFilter);
      currentFilter.value = updatedFilter;
    };

    const sportSelectRootElement = ref(null);
    const isSportsMenuOpen = ref(false);
    const isSportsLoading = ref(false);
    const allSports = ref([]);

    const competitionSelectRootElement = ref(null);
    const isCompetitionsMenuOpen = ref(false);
    const isCompetitionsLoading = ref(false);
    const allCompetitions = ref([]);
    const competitionSearch = ref('');

    const selectedSport = computed(() => currentFilter.value?.parameter?.sport);
    const selectedSportLabel = computed(() => selectedSport.value?.sportName || 'N/A');
    const selectedCompetitions = computed(() => currentFilter.value?.parameter?.competitions);
    const selectedCompetitionsLabel = computed(() => {
      const { competitionName = 'N/A' } = selectedCompetitions.value?.[0] || {};
      return competitionName;
    });
    const selectedCompetitionLabelSuffix = computed(() => {
      const lengthWithoutFirst = (selectedCompetitions.value?.length || 1) - 1;
      return lengthWithoutFirst > 0 ? ` +${lengthWithoutFirst}` : '';
    });

    const initializeData = async () => {
      isSportsLoading.value = true;
      isCompetitionsLoading.value = true;
      try {
        const { sports, competitions } = await findSportsAndCompetitions({
          sportId: selectedSport.value?.sportId || '',
          searchValue: trim(competitionSearch.value || ''),
        });
        allSports.value = sports;
        allCompetitions.value = competitions;
      } catch (error) {
        allSports.value = [];
        allCompetitions.value = [];
      } finally {
        isSportsLoading.value = false;
        isCompetitionsLoading.value = false;
      }
    };
    const loadData = debounce(async () => {
      isCompetitionsLoading.value = true;
      allCompetitions.value = [];
      try {
        const { competitions } = await findSportsAndCompetitions({
          sportId: selectedSport.value?.sportId || '',
          searchValue: trim(competitionSearch.value || ''),
        });
        allCompetitions.value = competitions;
      } catch (error) {
        console.error(error);
        allCompetitions.value = [];
      } finally {
        isCompetitionsLoading.value = false;
      }
    }, 350);
    const isSportSelected = ({ sportId }) => selectedSport.value?.sportId === sportId;
    const setSport = (sport) => {
      if (!isSportSelected(sport)) {
        currentFilter.value.conditionType = 'EQUAL';
        currentFilter.value.parameter = {
          sport: cloneDeep(sport),
          competitions: [],
        };
        syncCurrentFilter();
      }
      isSportsMenuOpen.value = false;
    };
    const isCompetitionSelected = ({ competitionId }) => includes(map(selectedCompetitions.value, 'competitionId'), competitionId);
    const toggleCompetition = (competition) => {
      if (isCompetitionSelected(competition)) {
        currentFilter.value.parameter.competitions = filter(
          currentFilter.value.parameter.competitions,
          ({ competitionId }) => competition.competitionId !== competitionId,
        );
      } else {
        currentFilter.value.parameter.competitions = [
          ...currentFilter.value.parameter.competitions,
          competition,
        ];
      }
    };
    const removeSport = () => {
      isSportsMenuOpen.value = false;
      isCompetitionsMenuOpen.value = false;
      currentFilter.value.parameter.sport = null;
      currentFilter.value.parameter.competitions = [];
      syncCurrentFilter();
      emit('removeFilter', currentFilter.value);
    };
    const removeCompetition = () => {
      isSportsMenuOpen.value = false;
      isCompetitionsMenuOpen.value = false;
      currentFilter.value.parameter.competitions = [];
      syncCurrentFilter();
    };
    const toggleSportsMenu = () => {
      isSportsMenuOpen.value = !isSportsMenuOpen.value;
    };
    const toggleCompetitionsMenu = () => {
      if (!selectedSport.value) return;
      if (isCompetitionsMenuOpen.value) syncCurrentFilter();
      isCompetitionsMenuOpen.value = !isCompetitionsMenuOpen.value;
    };

    onMounted(() => {
      initializeData();
    });

    watch(
      () => [
        selectedSport?.value?.sportId || '',
        competitionSearch.value,
      ],
      () => {
        loadData();
      },
    );

    onClickOutside(sportSelectRootElement, () => {
      isSportsMenuOpen.value = false;
    });
    onClickOutside(competitionSelectRootElement, () => {
      if (isCompetitionsMenuOpen.value) syncCurrentFilter();
      isCompetitionsMenuOpen.value = false;
    });

    return {
      currentFilter,
      isSportsMenuOpen,
      sportSelectRootElement,
      isCompetitionsMenuOpen,
      competitionSelectRootElement,
      selectedSport,
      selectedSportLabel,
      selectedCompetitions,
      selectedCompetitionsLabel,
      selectedCompetitionLabelSuffix,
      initializeData,
      loadData,
      isSportsLoading,
      isCompetitionsLoading,
      allSports,
      allCompetitions,
      competitionSearch,
      isSportSelected,
      setSport,
      isCompetitionSelected,
      toggleCompetition,
      removeSport,
      removeCompetition,
      toggleSportsMenu,
      toggleCompetitionsMenu,
    };
  },
};
</script>

<style lang="scss">
.bet-ticker-filter-sport-and-competition-select {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &__form-label {
    width: 100%;
    color: #6D6D6D;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }

  &__select {
    position: relative;
    width: 100%;

    &--is-disabled {
      .bet-ticker-filter-sport-and-competition-select__select-field {
        background-color: #F0F0F0;
        color: #A9A9A9;
        cursor: not-allowed;
      }
    }
  }

  &__select-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 32px;
    gap: 8px;
    cursor: pointer;
    position: relative;

    &--is-active {
      outline: 1px solid #a2bfff;
    }

    &-value {
      display: flex;
      align-items: center;
      gap: 4px;
      overflow: hidden;
    }

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      flex-shrink: 0;

      svg {
        stroke: #191414;
      }
    }
  }

  &__select-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 230px;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;
  }

  &__select-item {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 32px;
    padding: 8px;

    &:hover,
    &:focus {
      background-color: #FAFAFA;
      outline: none;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .radio-input,
    .checkbox {
      width: 14px;
      height: 14px;
    }
  }

  &__select-search {
    background-color: inherit;
    position: sticky;
    top: 0;
    padding: 8px;
  }
}
</style>
